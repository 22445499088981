import React from "react";
import { useGet, usePost } from "../../common/hooks";
import { Player } from "../../common/interfaces";
import {
  getPlayerDisplayForAdminPage,
  sortPlayersByDivisionAndJersey
} from "../../common/utils";
import { Button, Divider, Select } from "antd";
import Icon from '@ant-design/icons';
import { CustomPenalties } from "../components/CustomPenalties";
import { FileUpload } from "../../common/components/FileUpload";
import {useNavigate} from "react-router";
import {PersonDashboardUrl} from "../routes";

export function AdminDashboard() {
  let navigate = useNavigate();
  const allPlayers = useGet<Player[]>("/api/getPlayers");
  const numMessedUpEntities = useGet<string[]>("/api/updateEntity");
  const updateEntities = usePost({ uri: "/api/updateEntity" });
  const selectOpts = sortPlayersByDivisionAndJersey(allPlayers.data || []);
  const exportPath = "https://players.ncwhl.hockey/api/exportData";

  const onSelectPlayer = (personId: number) => {
    navigate(PersonDashboardUrl.createUrl(personId));
  };

  return (
    <>
      <Divider>Players</Divider>
      <div style={{ marginBottom: 10 }}>Select a player to update:</div>
      <Select
        onChange={onSelectPlayer}
        showSearch={true}
        style={{ width: 250 }}
        placeholder={"Select a player"}
        loading={allPlayers.isLoading}
        optionFilterProp={"children"}
      >
        {selectOpts.map((player: Player) => (
          <Select.Option key={player.id} value={player.id}>
            {getPlayerDisplayForAdminPage(player)}
          </Select.Option>
        ))}
      </Select>
      <Divider>Custom Penalties</Divider>
      <div>
        All penalties should use the defined enum, so fix any that were entered
        with custom text. If you see a new type of penalty, you'll need to add
        it to the PenaltyType enum.
      </div>
      <CustomPenalties />
      <Divider>Local DB</Divider>
      <div>Download and upload DB data, for testing purposes.</div>
      <a href={exportPath} target={"_blank"}>
        <Button style={{ margin: 10 }}>
          <Icon type="download" /> Download Production DB
        </Button>
      </a>
      <FileUpload
        apiPath={"/api/importData"}
        uploadLabel={"Upload DB File"}
        failureMessage={"Failed to update DB."}
        successMessage={"Successfully updated DB!"}
      />
      <div>
        {numMessedUpEntities.data ? "Done" : "Loading..."}
        {(numMessedUpEntities.data || []).map((id: string) => {
          return <div>id: {id}</div>;
        })}
      </div>
      <Button
        onClick={() => updateEntities.send({})}
        loading={updateEntities.isLoading}
        type={"primary"}
        style={{ margin: 10 }}
      >
        Update Entities
      </Button>
    </>
  );
}
